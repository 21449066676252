import React from 'react';
import Box from '@material-ui/core/Box';
import ContentSection from "../components/ContentSection";
import GridContainer from "../components/GridContainer";
import SEO from "../components/utility/SEO";
import IconList from "../components/IconList";
import {graphql} from "gatsby";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
  text: {
    textAlign: 'left',
    maxWidth: 700,
  },
  title: {
    textAlign: 'center',
    maxWidth: 700,
  },
  link: {
    textDecoration: 'none',
  },
  button: {
    marginLeft: theme.spacing(2),
    width: theme.spacing(17),
  },
  buttonLabel: {
    color: theme.palette.black,
  }
}));

export default function BondGoggles({data}) {
  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  if (!data || !data.prismicBondGogglesPage) return '';
  const {data: pageData} = data.prismicBondGogglesPage;

  function handleReverse(index) {
    return index % 2 === 0;
  }

  return (
      <>
        <SEO title={pageData.meta_title ? pageData.meta_title : "Bond goggles"}/>
        <Box>
          <ContentSection
              imageBottom
              image={pageData.image ? pageData.image.url : null}
              title={pageData.title}
              subtitle={pageData.subtitle}
              style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}/>
          <IconList
              disableColors={pageData.feature_list_icons_backgroundcolor === 'Disabled'}
              iconTextArray={pageData.feature_list}
              titleText={pageData.feature_list_title}
              style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}
              singleColumn={matches}/>
          {pageData.body && pageData.body.map((content, index) => {
            if (content.primary) {
              return (
                  <ContentSection
                      reverse={handleReverse(index)}
                      key={index}
                      url={content.primary.url}
                      image={content.primary.image ? content.primary.image.url : null}
                      title={content.primary.title}
                      subtitle={content.primary.subtitle}
                      button={content.primary.button}
                      style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}
                  />
              )
            } else if (content.cta_primary) {
              return (
                <GridContainer gridItemProps={{xs: 12, md: 12, style: !matches ? {padding: 8} : {padding: 16}}}
                              style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}>
                  <Typography variant={"h4"}
                              className={classes.title}>{content.cta_primary.title.text}</Typography>
                  <Typography variant={"subtitle1"} className={classes.title}>{content.cta_primary.paragraph.text}</Typography>
                  <a href={content.cta_primary.button_link.url} className={classes.link}>
                    <Button
                        variant="outlined"
                        size="medium"
                        color="primary"
                        classes={{label: classes.buttonLabel, startIcon: classes.buttonLabel}}
                        className={classes.button}
                        startIcon={<DraftsOutlinedIcon/>}>
                      Let's Talk
                    </Button>
                  </a>
                </GridContainer>
              )
            } else {
              return '';
            }
          })}
        </Box>
      </>
  );
}

export const bondGogglesPageQuery = graphql`
    query BondGogglesPage($id: String!) {
        prismicBondGogglesPage(id: {eq: $id}) {
            data {
                meta_title
                title
                subtitle
                image {
                    url
                }
                feature_list_title
                feature_list_icons_backgroundcolor
                feature_list {
                    text
                    icon {
                        url
                    }
                }
                body {
                    ... on PrismicBondGogglesPageBodyContentSection {
                        primary {
                            title
                            subtitle
                            button
                            url
                            image {
                                url
                            }
                        }
                    }
                    ... on PrismicBondGogglesPageBodyCallToAction {
                        cta_primary: primary {
                          button_label
                          icon_image {
                            alt
                            url
                          }
                          button_link {
                            url
                          }
                          paragraph {
                            text
                          }
                          title {
                            text
                          }
                        }
                    }
                }
            }
        }
    }
`;
